@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;800&family=Source+Sans+Pro:wght@400;600;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


  .app{
    display: flex;
  }
  
  .collapsedSidebar{
    width: 100px;
    transform: scaleX(200px);
  }
  
  .normalSidebar{
    width: 180px;
  }
  
  .content{
    flex: 1;
  }
  
  .main-content{
    margin: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .topbar {
    height: 60px;
    background-color: #333;
    color: #fff;
  }

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}
.contenedor-onda {
  width: 36px; /* Ancho del contenedor */
  height: 36px; /* Altura del contenedor */

  border-radius: 50%;
  position: relative; /* Posición relativa para que la onda esté centrada */
  cursor: pointer;
}

/* Estilos para la onda */
.onda {
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%; /* Hace que la onda sea circular */
  opacity: 0;
  top: 50%; /* Centra verticalmente */
  left: 50%; /* Centra horizontalmente */
  transform: translate(-50%, -50%); /* Centra el elemento en el centro */
  animation: wave 2s infinite; /* Animación infinita llamada "wave" */
}


.icono {
  position: absolute;
  top: 50%; /* Centra verticalmente */
  left: 50%; /* Centra horizontalmente */
  transform: translate(-50%, -50%); /* Centra el elemento en el centro */
  font-size: 24px; /* Tamaño del ícono */
  color: white; /* Color del ícono */
}


.imagen-sol {
  background-image: url('../public/assets/sun.png'); /* Reemplaza con la ruta de tu imagen */
  background-size: 75px 100%; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-repeat: no-repeat;
  background-position: center; /* Centra la imagen en el contenedor */
  text-align: center; /* Alinea el texto en el centro */
  padding: 25px; /* Añade espaciado interno para el texto */
  opacity: 0.9;
  
}

.imagen-sol h5{
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 14%;
}

.imagen-luna {
  background-image: url('../public/assets/moon.png'); /* Reemplaza con la ruta de tu imagen */
  background-repeat: no-repeat; 
  background-size: 55px 75%; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  color: black; /* Cambia el color del texto para que sea legible */
  text-align: center; /* Alinea el texto en el centro */
  padding: 25px; /* Añade espaciado interno para el texto */  
  opacity: 0.9;
}

.imagen-luna h5{
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 14%;
}


@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  50% {
    width: 35px; /* Tamaño deseado de la onda */
    height: 35px;
    opacity: 0.7; /* Opacidad deseada */
  }
  100% {
    width: 0;
    height: 0;
    opacity: 0;
  }
}


